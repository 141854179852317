<template>
  <div class="CommonBgColor">
    <!-- 废弃 -->
    
    <!-- 现在使用 -->
    <header class="color-mode-title" style="display:none">
      <template v-if="colorGRB"> RGB </template>
      <template v-else> CMYK </template>
    </header>
    <div class="color-content-box">
      <template v-if="colorGRB">
        <div class="no-color" @click="clickBusColor" ref="styleColor">
          <img src="../../../../assets/img/color/nocolor.png" alt="" />
        </div>
        <!-- 调色板主体色块 -->
        <div
          class="color-content-header"
          v-for="(item, index) in noNeedMoneyColors"
          :key="index">
          <div
            class="color-content-small color-content-samll-header"
            :style="{
              'background-color': 'rgb(' + item.headerColor.headerColor + ')',
            }"
            @mouseover="
              mouseover(
                index,
                item.headerColor.headerColorId,
                item.headerColor.isMember
              )
            "
            @mouseleave="mouseleave"
            @click="
              clickBusColor(
                index,
                item.headerColor.headerColorId,
                item.headerColor.isMember
              )
            "
            ref="styleColor"
          >
            <div class="color-need-money">
              <a-icon
                v-if="item.headerColor.needMoney != ''"
                :type="item.headerColor.needMoney"
                class="need-lock"
              />
            </div>
          </div>
          <div
            class="color-content-small"
            :style="{
              'background-color':
                'rgb(' + item.smallColor.smallColorOne.smallColorOne + ')',
            }"
            @mouseover="
              mouseover(
                index,
                item.smallColor.smallColorOne.smallColorId,
                item.isMember
              )
            "
            @mouseleave="mouseleave"
            @click="
              clickBusColor(
                index,
                item.smallColor.smallColorOne.smallColorId,
                item.smallColor.smallColorOne.isMember
              )
            "
            ref="styleColor"
          >
            <div class="color-need-money">
              <a-icon
                v-if="item.headerColor.needMoney != ''"
                :type="item.headerColor.needMoney"
                class="need-lock"
              />
            </div>
          </div>
          <div
            class="color-content-small"
            :style="{
              'background-color':
                'rgb(' + item.smallColor.smallColorTwo.smallColorTwo + ')',
            }"
            @mouseover="
              mouseover(index, item.smallColor.smallColorTwo.smallColorId)
            "
            @mouseleave="mouseleave"
            @click="
              clickBusColor(
                index,
                item.smallColor.smallColorTwo.smallColorId,
                item.smallColor.smallColorTwo.isMember
              )
            "
            ref="styleColor"
          >
            <div class="color-need-money">
              <a-icon
                v-if="item.headerColor.needMoney != ''"
                :type="item.headerColor.needMoney"
                class="need-lock"
              />
            </div>
          </div>
          <div
            class="color-content-small"
            :style="{
              'background-color':
                'rgb(' + item.smallColor.smallColorThree.smallColorThree + ')',
            }"
            @mouseover="
              mouseover(index, item.smallColor.smallColorThree.smallColorId)
            "
            @mouseleave="mouseleave"
            @click="
              clickBusColor(
                index,
                item.smallColor.smallColorThree.smallColorId,
                item.smallColor.smallColorTwo.isMember
              )
            "
            ref="styleColor"
          >
            <div class="color-need-money">
              <a-icon
                v-if="item.headerColor.needMoney != ''"
                :type="item.headerColor.needMoney"
                class="need-lock"
              />
            </div>
          </div>
          <div
            class="color-content-small"
            :style="{
              'background-color':
                'rgb(' + item.smallColor.smallColorFour.smallColorFour + ')',
            }"
            @mouseover="
              mouseover(index, item.smallColor.smallColorFour.smallColorId)
            "
            @mouseleave="mouseleave"
            @click="
              clickBusColor(
                index,
                item.smallColor.smallColorFour.smallColorId,
                item.smallColor.smallColorTwo.isMember
              )
            "
            ref="styleColor"
          >
            <div class="color-need-money">
              <a-icon
                v-if="item.headerColor.needMoney != ''"
                :type="item.headerColor.needMoney"
                class="need-lock"
              />
            </div>
          </div>
          <div
            class="color-content-small"
            :style="{
              'background-color':
                'rgb(' + item.smallColor.smallColorFive.smallColorFive + ')',
            }"
            @mouseover="
              mouseover(index, item.smallColor.smallColorFive.smallColorId)
            "
            @mouseleave="mouseleave"
            @click="
              clickBusColor(
                index,
                item.smallColor.smallColorFive.smallColorId,
                item.smallColor.smallColorTwo.isMember
              )
            "
            ref="styleColor"
          >
            <div class="color-need-money">
              <a-icon
                v-if="item.headerColor.needMoney != ''"
                :type="item.headerColor.needMoney"
                class="need-lock"
              />
            </div>
          </div>
          <div
            class="color-content-small"
            :style="{
              'background-color':
                'rgb(' + item.smallColor.smallColorSix.smallColorSix + ')',
            }"
            @mouseover="
              mouseover(index, item.smallColor.smallColorSix.smallColorId)
            "
            @mouseleave="mouseleave"
            @click="
              clickBusColor(
                index,
                item.smallColor.smallColorSix.smallColorId,
                item.smallColor.smallColorTwo.isMember
              )
            "
            ref="styleColor"
          >
            <div class="color-need-money">
              <a-icon
                v-if="item.headerColor.needMoney != ''"
                :type="item.headerColor.needMoney"
                class="need-lock"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <section class="cmyk-color-box">
          <div
            class="cmyk-color-item"
            v-for="(item, index) in cmykColors"
            :key="index"
          >
            <section
              class="cmyk-show-color"
              v-for="(sitem, sindex) in item.colorsValue"
              :key="sindex"
              :style="{
                'background-color': 'rgb' + sitem.rgbValue,
              }"
              @click="setColor('rgb' + sitem.rgbValue)"
            ></section>
          </div>
        </section>
      </template>

      <!-- 标准颜色 -->
      <div class="color-content-max-titel flex-between" @click.stop="">
        <div class="standard-colors">
          {{ $getStringObj.getString($Strings.Standard_Colors) }}
        </div>
        <section class="color-mode">
          <a-checkbox :checked="colorGRB" @change="onChangeColorMode">
            <span class="color-style"> GRB </span>
          </a-checkbox>
          <a-checkbox :checked="colorCMYK" @change="onSwitchColorMode">
            <span class="color-style"> CMYK </span>
          </a-checkbox>
        </section>
      </div>
      <div class="recently-colors-title">
          {{ $getStringObj.getString($Strings.Mind_Edit_Recently_Used) }}
      </div>
      <div
        class="color-content-max-box color"
        v-for="(item, index) in noNeedMoneyColorNorm"
        :key="item.ColorNormColorId"
      >
        <div
          class="color-content-small"
          :style="{ 'background-color': 'rgb(' + item.ColorNormColor + ')' }"
          @mouseover="mouseover(index, item.ColorNormColorId)"
          @mouseleave="mouseleave"
          @click="clickBusColor(index, item.ColorNormColorId, item.isMember)"
          ref="styleColor"
        >
          <div class="color-need-money">
            <a-icon
              v-if="item.needMoney != ''"
              :type="item.needMoney"
              class="need-lock"
            />
          </div>
        </div>
      </div>
      <!-- 更多颜色  -->
      <div class="color-content-max-titel" style="margin-top:35px; line-heigth:24">
        <div class="color-setting-content">
          <span class="color-setting-tag">
            #
          </span>
          <a-input
            class="color-setting"
            value="FFFFFF"
            v-model="customColor"
            @blur="onChangeColorText"
            @input="onChangeColorInput"
            @keyup.enter="colorSettingInputKeyEnter" 
          />
          <div class="color-setting-separator"/>
          <a-input
              class="color-alpha-setting"
              placeholder="100"
              value="100"
              v-model="customAlhpe"
              @blur="onChangeColorAlphaText"
              @input="onChangeColorAlphaInput"
              @keyup.enter="onChangeColorAlphaInputEnter" 
            />
          <span class="color-alpha-tag">
            %
          </span>
        </div>
        
        <span class="cursor-pointer"  @click.stop="moreColor" style="margin-left:10px">
          {{ $getStringObj.getString($Strings.More_Colors) }}
        </span>
        <!--  @mouseover="mouseover(index, item.ColorNormColorId)"
          @mouseleave="mouseleave"
          @click="clickBusColor(index, item.ColorNormColorId, item.isMember)"
              v-if="item.needMoney != ''"
              :type="item.needMoney" -->
        <!-- <div
          class="color-content-small"
          :style="{ 'background-color': 'rgb(255, 152, 152)','position': 'absolute',
          'margin-top': '12px' }"
              >
              </div>
              <div style="width: 100px;
              position: relative;
              top: 14px;
              left: 25px;">
              修改
              <a-slider
                    v-model="opacity"
                    :tooltip-visible="false"
                    :min="0"
                    :max="100"
                    @change="changeSliderVal"
                  />
              </div>
              <div class="slider-val" >{{opacity}}%</div>
              <div class="more-colors-img" @click="moreColor">
                  <img src="../../../../assets/img/mapIcon/res/mipmap-right-menu/more_color.svg"/>
              </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
import { cmykColors } from "./colorData.js";
import { localStorageKey } from "../../../../assets/js/GlobalVariable.js";
import EditMindmapVM from '../../../../viewmodel/mindelementdata/vm/EditMindmapVM.js';
import MindMe from '../../../../viewmodel/facade/MindMe.js';

export default {
  name: "CommonBgColor",
  data() {
    return {
      colorGRB: true, //true显示rgb颜色
      colorCMYK: false, //true显示cmyk颜色
      cmykColors,
      customColor: "FFFFFF",
      customAlhpe: "100",
      noNeedMoneyColors: [
        { //第1列
          headerColor: {
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "252, 227, 226",
              smallColorId: 1,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "246, 168, 168",
              smallColorId: 2,
              needMoney: "",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "245, 107, 106",
              smallColorId: 3,
              needMoney: "",
              isMember: true,
            },
            smallColorFour: {
              smallColorFour: "245, 82, 83",
              smallColorId: 4,
              needMoney: "",
              isMember: true,
            },
            smallColorFive: {
              smallColorFive: "240, 61, 63",
              smallColorId: 5,
              needMoney: "",
              isMember: true,
            },
            smallColorSix: {
              smallColorSix: "201, 42, 42",
              smallColorId: 6,
              needMoney: "",
              isMember: true,
            },
          },          
        },
        { //第2列
          headerColor: {
            headerColor: "255, 255, 255",
            headerColorId: 7,
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "252, 232, 204",
              smallColorId: 8,
              isMember: true,
              needMoney: "",
            },

            smallColorTwo: {
              smallColorTwo: "248, 193, 120",
              smallColorId: 9,
              isMember: true,
              needMoney: "",
            },

            smallColorThree: {
              smallColorThree: "246, 145, 44",
              smallColorId: 10,
              isMember: true,
              needMoney: "",
            },

            smallColorFour: {
              smallColorFour: "246, 125, 22",
              smallColorId: 11,
              isMember: true,
              needMoney: "",
            },

            smallColorFive: {
              smallColorFive: "246, 103, 7",
              smallColorId: 12,
              isMember: true,
              needMoney: "",
            },

            smallColorSix: {
              smallColorSix: "217, 72, 16",
              smallColorId: 13,
              isMember: true,
              needMoney: "",
            },
          },
        },
        { //第3列
          headerColor: {
            headerColor: "238, 238, 238",
            headerColorId: 14,
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "252, 243, 190",
              smallColorId: 15,
              isMember: true,
              needMoney: "",
            },

            smallColorTwo: {
              smallColorTwo: "250, 223, 102",
              smallColorId: 16,
              isMember: true,
              needMoney: "",
            },

            smallColorThree: {
              smallColorThree: "249, 196, 24",
              smallColorId: 17,
              isMember: true,
              needMoney: "",
            },

            smallColorFour: {
              smallColorFour: "247, 176, 8",
              smallColorId: 18,
              isMember: true,
              needMoney: "",
            },

            smallColorFive: {
              smallColorFive: "245, 159, 0",
              smallColorId: 19,
              isMember: true,
              needMoney: "",
            },

            smallColorSix: {
              smallColorSix: "230, 119, 2",
              smallColorId: 20,
              isMember: true,
              needMoney: "",
            },
          },          
        },
        { //第4列
          headerColor: {
            headerColor: "211, 211, 211",
            headerColorId: 21,
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "233, 250, 200",
              smallColorId: 22,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "192, 236, 117",
              smallColorId: 23,
              needMoney: "",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "148, 216, 44",
              smallColorId: 24,
              needMoney: "",
              isMember: true,
            },
            smallColorFour: {
              smallColorFour: "129, 201, 30",
              smallColorId: 25,
              needMoney: "",
              isMember: true,
            },
            smallColorFive: {
              smallColorFive: "116, 184, 22",
              smallColorId: 26,
              needMoney: "",
              isMember: true,
            },
            smallColorSix: {
              smallColorSix: "92, 147, 15",
              smallColorId: 27,
              needMoney: "",
              isMember: true,
            },
          },
        },
        { //第5列
          headerColor: {
            headerColor: "178, 178, 178",
            headerColorId: 28,
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "211, 249, 217",
              smallColorId: 29,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "140, 233, 154",
              smallColorId: 30,
              needMoney: "",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "81, 207, 102",
              smallColorId: 31,
              needMoney: "",
              isMember: true,
            },
            smallColorFour: {
              smallColorFour: "64, 192, 87",
              smallColorId: 32,
              needMoney: "",
              isMember: true,
            },
            smallColorFive: {
              smallColorFive: "54, 178, 77",
              smallColorId: 33,
              needMoney: "",
              isMember: true,
            },
            smallColorSix: {
              smallColorSix: "43, 138, 62",
              smallColorId: 34,
              needMoney: "",
              isMember: true,
            },
          },
        },
        { //第6列
          headerColor: {
            headerColor: "153, 153, 153",
            headerColorId: 35,
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "195, 250, 232",
              smallColorId: 36,
              isMember: true,
            },
            smallColorTwo: {
              smallColorTwo: "99, 230, 190",
              smallColorId: 37,
              needMoney: "",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "33, 201, 151",
              smallColorId: 38,
              needMoney: "",
              isMember: true,
            },
            smallColorFour: {
              smallColorFour: "16, 184, 133",
              smallColorId: 39,
              needMoney: "",
              isMember: true,
            },
            smallColorFive: {
              smallColorFive: "14, 166, 120",
              smallColorId: 40,
              needMoney: "",
              isMember: true,
            },
            smallColorSix: {
              smallColorSix: "8, 127, 92",
              smallColorId: 41,
              needMoney: "",
              isMember: true,
            },
          },
        },
        { //第7列
          headerColor: {
            headerColor: "127, 127, 127",
            headerColorId: 42,
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "198, 246, 250",
              smallColorId: 43,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "101, 217, 233",
              smallColorId: 44,
              needMoney: "",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "34, 184, 207",
              smallColorId: 45,
              needMoney: "",
              isMember: true,
            },
            smallColorFour: {
              smallColorFour: "19, 170, 191",
              smallColorId: 46,
              needMoney: "",
              isMember: true,
            },
            smallColorFive: {
              smallColorFive: "16, 152, 173",
              smallColorId: 47,
              needMoney: "",
              isMember: true,
            },
            smallColorSix: {
              smallColorSix: "12, 114, 133",
              smallColorId: 48,
              needMoney: "",
              isMember: true,
            },
          },
        },
        { //第8列
          headerColor: {
            headerColor: "102, 102, 102",
            headerColorId: 49,
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "208, 236, 255",
              smallColorId: 50,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "117, 192, 252",
              smallColorId: 51,
              needMoney: "",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "51, 154, 240",
              smallColorId: 52,
              needMoney: "",
              isMember: true,
            },
            smallColorFour: {
              smallColorFour: "34, 139, 230",
              smallColorId: 53,
              needMoney: "",
              isMember: true,
            },
            smallColorFive: {
              smallColorFive: "27, 126, 213",
              smallColorId: 54,
              needMoney: "",
              isMember: true,
            },
            smallColorSix: {
              smallColorSix: "23, 100, 171",
              smallColorId: 55,
              needMoney: "",
              isMember: true,
            },
          },
        },        
        { //第9列
          headerColor: {
            headerColor: "76, 76, 76",
            headerColorId: 56,
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "219, 228, 255",
              smallColorId: 57,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "145, 167, 255",
              smallColorId: 58,
              needMoney: "",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "93, 124, 250",
              smallColorId: 59,
              needMoney: "",
              isMember: true,
            },
            smallColorFour: {
              smallColorFour: "76, 110, 245",
              smallColorId: 60,
              needMoney: "",
              isMember: true,
            },
            smallColorFive: {
              smallColorFive: "67, 99, 235",
              smallColorId: 61,
              needMoney: "",
              isMember: true,
            },
            smallColorSix: {
              smallColorSix: "54, 79, 199",
              smallColorId: 62,
              needMoney: "",
              isMember: true,
            },
          },
        },
        { //第10列
          headerColor: {
            headerColor: "51, 51, 51",
            headerColorId: 63,
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "229, 219, 255",
              smallColorId: 64,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "177, 151, 252",
              smallColorId: 65,
              needMoney: "",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "132, 94, 248",
              smallColorId: 66,
              needMoney: "",
              isMember: true,
            },
            smallColorFour: {
              smallColorFour: "121, 80, 242",
              smallColorId: 67,
              needMoney: "",
              isMember: true,
            },
            smallColorFive: {
              smallColorFive: "112, 71, 232",
              smallColorId: 68,
              needMoney: "",
              isMember: true,
            },
            smallColorSix: {
              smallColorSix: "95, 61, 196",
              smallColorId: 69,
              needMoney: "",
              isMember: true,
            },
          },
        },
        { //第11列
          headerColor: {
            headerColor: "25, 25, 25",
            headerColorId: 70,
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "244, 216, 250",
              smallColorId: 71,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "220, 153, 248",
              smallColorId: 72,
              needMoney: "",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "204, 93, 232",
              smallColorId: 73,
              needMoney: "",
              isMember: true,
            },
            smallColorFour: {
              smallColorFour: "190, 75, 219",
              smallColorId: 74,
              needMoney: "",
              isMember: true,
            },
            smallColorFive: {
              smallColorFive: "174, 62, 200",
              smallColorId: 75,
              needMoney: "",
              isMember: true,
            },
            smallColorSix: {
              smallColorSix: "133, 45, 156",
              smallColorId: 76,
              needMoney: "",
              isMember: true,
            },
          },
        },
        { //第12列
          headerColor: {
            headerColor: "0, 0, 2",
            headerColorId: 77,
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "255, 222, 236",
              smallColorId: 78,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "250, 162, 193",
              smallColorId: 79,
              needMoney: "",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "240, 101, 149",
              smallColorId: 80,
              needMoney: "",
              isMember: true,
            },
            smallColorFour: {
              smallColorFour: "230, 73, 128",
              smallColorId: 81,
              needMoney: "",
              isMember: true,
            },
            smallColorFive: {
              smallColorFive: "213, 51, 108",
              smallColorId: 82,
              needMoney: "",
              isMember: true,
            },
            smallColorSix: {
              smallColorSix: "166, 30, 77",
              smallColorId: 83,
              needMoney: "",
              isMember: true,
            },
          },
        },
        
      ],
      noNeedMoneyColorNorm: [
        {
          ColorNormColor: "192, 0, 0",
          ColorNormColorId: 84,
          isMember: true,
          needMoney: "",
        },
        {
          ColorNormColor: "255, 0, 0",
          ColorNormColorId: 85,
          isMember: true,
          needMoney: "",
        },
        {
          ColorNormColor: "249, 102, 40",
          ColorNormColorId: 86,
          needMoney: "",
          isMember: true,
        },
        {
          ColorNormColor: "255, 192, 0",
          ColorNormColorId: 87,
          needMoney: "",
          isMember: true,
        },
        {
          ColorNormColor: "255, 255, 0",
          ColorNormColorId: 88,
          needMoney: "",
          isMember: true,
        },
        {
          ColorNormColor: "146, 208, 80",
          ColorNormColorId: 89,
          needMoney: "",
          isMember: true,
        },
        {
          ColorNormColor: "47,176,88",
          ColorNormColorId: 90,
          needMoney: "",
          isMember: true,
        },
        {
          ColorNormColor: "21,150,144",
          ColorNormColorId: 91,
          needMoney: "",
          isMember: true,
        },
        {
          ColorNormColor: "82,196,26",
          ColorNormColorId: 92,
          needMoney: "",
          isMember: true,
        },
        {
          ColorNormColor: "40, 136, 204",
          ColorNormColorId: 93,
          needMoney: "",
          isMember: true,
        },
        {
          ColorNormColor: "17,37,185",
          ColorNormColorId: 94,
          needMoney: "",
          isMember: true,
        },
        {
          ColorNormColor: "112, 48, 160",
          ColorNormColorId: 95,
          needMoney: "",
          isMember: true,
        },
      ],

      ColorClick: true,

      isMember: false,
      opacity: 100,
    };
  },
  created() {
    //是否会员
    let userData = localStorage.getItem("meMessage");
    if (userData == null || userData == "") {
      return;
    }
    if (userData != null) {
      let userDataObj = JSON.parse(userData);
      this.isMember = userDataObj.isMember;
    }
    this.refreshHistoryColor(false)
  },
  mounted() {
    this.isMember = true; //颜色收费作废
    let colorMode = JSON.parse(localStorage.getItem(localStorageKey.ColorMode));
    if (colorMode == false) {
      this.colorGRB = colorMode;
      this.colorCMYK = !colorMode;
    }
    
  },
  components: {},
  methods: {
    ...mapMutations([
      "backGroundColor",
      "showMoreColor",
      "showChunkColor",
      "backBorderColor",
      "backTextcolor",
      "backBorderMinorStyle",
      "backLineColor",
      "backFillColor",
      "backBaoWeiLineColor",
      "backBaoWeiFillColor",
      "textShadowColor",            
      "backChartFillColor",
      "backSpanTextColor",
      "backSpanTextBgColor",
      "backSpanTextShadowColor",
      "isShowShoppingModel",
    ]),
    mouseover(index, Id) {
      // var busColor = this.$refs.styleColor[(index, Id)].style.backgroundColor;
      // if (this.$store.state.colorAccout == 0) {
      //   this.backGroundColor(busColor);
      // } else if (this.$store.state.colorAccout == 1) {
      //   this.backLineColor(busColor);
      // } else if (this.$store.state.colorAccout == 2) {
      //   this.backTextcolor(busColor);
      // } else if (this.$store.state.colorAccout == 3) {
      //   this.backBorderColor(busColor);
      // } else if (this.$store.state.colorAccout == 4) {
      //   this.backFillColor(busColor);
      // } else if (this.$store.state.colorAccout == 5) {
      //   this.backBorderMinorStyle(busColor);
      // }
    },
    mouseleave() {
      // if (this.ColorClick) {
      //   if (this.$store.state.colorAccout == 0) {
      //     this.backGroundColor("rgb(255, 255, 255)");
      //   } else if (this.$store.state.colorAccout == 1) {
      //     this.backLineColor("rgb(138, 218, 205)");
      //   } else if (this.$store.state.colorAccout == 2) {
      //     this.backTextcolor("rgb(51, 51, 51)");
      //   } else if (this.$store.state.colorAccout == 3) {
      //     this.backBorderColor("rgb(138, 218, 205)");
      //   } else if (this.$store.state.colorAccout == 4) {
      //     this.backFillColor("rgb(255, 255, 255)");
      //   } else if (this.$store.state.colorAccout == 5) {
      //     this.backBorderMinorStyle("rgb(138, 218, 205)");
      //   }
      // }
    },
    moreColor() {
      this.showMoreColor(true);
    },
    onChangeColorMode(checkedValues) {
      // 切换rgb或cmyk颜色
      this.colorGRB = !this.colorGRB;
      this.colorCMYK = !this.colorGRB;
    },
    onSwitchColorMode(checkedValues) {
      // 切换rgb或cmyk颜色
      this.colorCMYK = !this.colorCMYK;
      this.colorGRB = !this.colorCMYK;
    },
    clickBusColor(index, Id, isMember) {
      this.ColorClick = false;
      setTimeout(() => {
        this.ColorClick = true;
      }, 100);
      //透明色 busColor = 1
      var busColor = 1
      if (this.$refs.styleColor[(index, Id)] == undefined) {
        busColor = 1; //"rgb(248, 248, 255)"
      } else {
        busColor = this.$refs.styleColor[(index, Id)].style.backgroundColor;
      }
      this.setColor(busColor);
      this.showChunkColor(false);
    },

    onChangeColorAlphaText(e) {
      this.onChangeColorAlphaInput(e)
    },

    onChangeColorAlphaInputEnter(e) {
      this.onChangeColorAlphaInput(e)
    },

    onChangeColorAlphaInput(e) {
      if (this.$store.state.colorAccout != 4 && this.$store.state.colorAccout != 7) {  
        return 
      }
      let value = e.target.value;
      if (value == null || value.length == 0 || value.indexOf(" ") > -1) {
        return value
      }
      var newValue = ""
      let count = value.length
      let numberStrs = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
      for (let index = 0; index < count; index++) {        
        let cell = value.substring(index, index + 1).toLowerCase()
        if (numberStrs.includes(cell)) {
          newValue = newValue + cell;
        }
      }
      if (newValue.length == 0) {
        return
      }
      var alpha = parseInt(newValue) /100
      if (alpha < 0) {
        alpha = 0
      } else if (alpha > 1) {
        alpha = 1
      }
      if (this.$store.state.colorAccout == 4 || this.$store.state.colorAccout == 7) {  
        customAlhpe =  Math.ceil(alpha * 100) + ""
        EditMindmapVM.changeFillColorColorAlpha(alpha)
      }
    },

    onChangeColorText(e) {
      let value = e.target.value;
      if (value == null || value.length < 3 || value.indexOf(" ") > -1) {
        return
      }
      
      let color = this.formatColor(value)
      let colorSetting = document.getElementsByClassName("color-setting");
      console.log("color*- ", color);
      if (colorSetting != null) {
        // colorSetting[0].value = color.toUpperCase()
        customColor = color.toUpperCase()
        console.log("color- ", color);
      }
    },
    colorSettingInputKeyEnter(e) {
      this.onChangeColorText(e)
    },

    onChangeColorInput(e) {
      let value = e.target.value;
      if (value == null || value.length < 4 || value.indexOf(" ") > -1) {
        return
      }
      
      let color = "#" + this.formatColor(value)
      customColor = color.toUpperCase()
      this.setColor(color);
    },

    formatColor(value) { //value:String
      if (value == null || value.length < 3 || value.indexOf(" ") > -1) {
        return value
      }
      var newValue = ""
      let count = value.length
      let numberStrs = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
      let lettersStrs = ["a", "b", "c", "d", "e", "f"]
      var lastCell = "0"
      for (let index = 0; index < count; index++) {
        if (index > 5) {
          break;
        }
        let cell = value.substring(index, index + 1).toLowerCase()
        if (numberStrs.includes(cell) || lettersStrs.includes(cell)) {
          newValue = newValue + cell;
          lastCell = cell;
        } else {
          newValue = newValue + lastCell;
        }
      }
      if (count == 3) {
        newValue = newValue + lastCell + lastCell + lastCell;
      } else if (count == 4) {
        newValue = newValue + lastCell + lastCell;
      } else if (count == 5) {
        newValue = newValue + lastCell;
      }     
      if (newValue == "000000") {
        newValue = "000002"
      }
      return newValue;
    },

    refreshHistoryColor(isRefreshUi = true) {
      let colors = MindMe.getHistoryColors()
      if (colors.length == 0) {
        return
      }
      let count = this.noNeedMoneyColorNorm.length
      for (let index = 0; index < colors.length; index++) {        
        if (index >= count) {
          break
        }
        const value = colors[index];
        let red = (value & 0xff0000) >> 16;
        let green = (value & 0x00ff00) >> 8;
        let blue = (value & 0x0000ff);
        this.noNeedMoneyColorNorm[index].ColorNormColor = red + ", " + green + ", " + blue;
        if (isRefreshUi && this.$refs.styleColor[(index, this.noNeedMoneyColorNorm[index].ColorNormColorId)] != null) {
          this.$refs.styleColor[(index, this.noNeedMoneyColorNorm[index].ColorNormColorId)].style.backgroundColor = "rgb(" + this.noNeedMoneyColorNorm[index].ColorNormColor + ")"
        }
      }
    },

    setColor(busColor) {
      MindMe.addHistoryColor(busColor)
      if (this.$store.state.colorAccout == 0) {
        this.backGroundColor(busColor);
        setTimeout(() => {
          this.backGroundColor(null);
        }, 100);
      } else if (this.$store.state.colorAccout == 1) {
        this.backLineColor(busColor);
        setTimeout(() => {
          this.backLineColor(null);
        }, 100);
      } else if (
        this.$store.state.colorAccout == 2 ||
        this.$store.state.colorAccout == 8
      ) {
        //设置文字颜色，或者关联线文字颜色，或者包裹线文字颜色。
        this.backTextcolor(busColor);
        setTimeout(() => {
          this.backTextcolor(null);
        }, 100);
      } else if (this.$store.state.colorAccout == 3) {
        this.backBorderColor(busColor);
        setTimeout(() => {
          this.backBorderColor(null);
        }, 100);
      } else if (this.$store.state.colorAccout == 4) {
        this.backFillColor(busColor);
        setTimeout(() => {
          this.backFillColor(null);
        }, 100);
      } else if (this.$store.state.colorAccout == 5) {
        this.backBorderMinorStyle(busColor);
        setTimeout(() => {
          this.backBorderMinorStyle(null);
        }, 100);
      } else if (this.$store.state.colorAccout == 6) {
        this.backBaoWeiLineColor(busColor);
        setTimeout(() => {
          this.backBaoWeiLineColor(null);
        }, 100);
      } else if (this.$store.state.colorAccout == 7) {
        this.backBaoWeiFillColor(busColor);
        setTimeout(() => {
          this.backBaoWeiFillColor(null);
        }, 100);
      } else if (this.$store.state.colorAccout == 9) {
        this.textShadowColor(busColor);
        setTimeout(() => {
          this.textShadowColor(null);
        }, 100);
      } else if (this.$store.state.colorAccout == "CHART_COLOR") {
        this.backChartFillColor(busColor);
        setTimeout(() => {
          this.backChartFillColor(null);
        }, 1000);
      } else if (this.$store.state.colorAccout == "TEXT_STYLE") {
        this.backSpanTextColor(busColor);
        setTimeout(() => {
          this.backSpanTextColor(null);
        }, 1000);
      } else if (this.$store.state.colorAccout == "TEXT_BG_COLOR_STYLE") {
        this.backSpanTextBgColor(busColor);
        setTimeout(() => {
          this.backSpanTextBgColor(null);
        }, 1000);
      } else if (this.$store.state.colorAccout == "TEXT_SHADOW_COLOR_STYLE") {
        this.backSpanTextShadowColor(busColor);
        setTimeout(() => {
          this.backSpanTextShadowColor(null);
        }, 1000);
      }
      this.refreshHistoryColor()
    },
    changeSliderVal() {
      // console.log("changeSliderVal")
    },
  },
  watch: {
    colorGRB(newVal) {
      localStorage.setItem(localStorageKey.ColorMode, newVal);
    },
    "$store.state.refreshHistoryColorIndex"(newLineColor, olLineColor) {
      if (newLineColor == null) {
        return;
      }      
      this.refreshHistoryColor(true);
    },
  },
};
</script>
<style lang='less' scoped>
.CommonBgColor {
  width: 264px;
  // height: 210px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(90, 90, 90, 0.35);
  position: absolute;
  z-index: 9999;
  background-color: #ffffffd8;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px)
}

.color-mode-title {
  width: 100%;
  height: 30px;
  font-size: 16px;
  font-weight: 450;
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
}
.color-content-box {
  position: relative;
  margin: 6px 6px;
}
.color-content-header {
  width: 20px;
  margin-right: 1px;
  height: 100%;
  float: left;
}
.color-content-small {
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 1px;
  cursor: pointer;
}

.color-content-samll-header {
  margin-bottom: 3px;
}
.cmyk-color-box {
  display: flex;
  .cmyk-color-item {
    width: 20px;
    margin-right: 1px;
    display: flex;
    flex-direction: column;
    .cmyk-show-color {
      width: 100%;
      height: 16px;
      cursor: pointer;
    }
  }
}
.color-content-max-titel {
  font-size: 12px;
  line-height: 30px;
  user-select: none;
  padding-left: 5px;
  font-weight: 550;
  color: #000;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  line-height: 35px;
  border-bottom: 1px solid #e9e9e9;
  .color-mode {
    display: flex;
    justify-content: space-between;
    .color-style {
      color: #333333;
      font-size: 12px;
      margin-left: -6px;
    }
  }
}
.color-content-max-box {
  position: relative;
}
.color-content-max-box .color-content-small {
  margin-right: 1px;
  float: left;
}
.color-content-small:hover {
  border: 1px solid #ffffff;
}
.no-color {
  position: absolute;
  top: -5px;
}
.color-need-money {
  width: 20px;
  height: 20px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.need-lock {
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 20px;
  height: 20px;
  transition: 0.5s;
}
.need-lock:hover {
  border: 1px solid #fc4c51;
  transition: 0.5s;
}

.slider-val {
  width: 38px;
  height: 21px;
  background: #ffffff;
  border: 1px solid #d4d4d4;
  opacity: 1;
  border-radius: 4px;
  position: relative;
  left: 143px;
  top: -12px;
  line-height: 21px;
  text-align: center;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
.color-setting {
  width: 43px;
  color:#333333;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  border: 0px solid #000;
  border-radius: 0px;
  background: #ffffff00;
  padding-left: 0px;
  padding-right: 0px;
}
.color-setting-tag {
  color:#333333;
  height: 20px;
  line-height: 20px;
  font-size: 13px;
  border: 0px solid #000;
  border-radius: 5px;
  background: #ffffff00;
  margin-right: 3px;
}
.color-alpha-tag {
  color:#333333;
  height: 20px;
  line-height: 20px;
  font-size: 13px;
  border: 0px solid #000;
  border-radius: 0px;
  background: #ffffff00;
  margin-left: 2px;
}

.color-setting-separator {
  width: 1px;
  height: 12px;
  border-radius: 0.5px;
  background: #c8c8c8;
  margin: 4px 8px 4px 6px;
}
.color-alpha-setting {
  width: 22px;
  color:#333333;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  border: 0px solid #000;
  border-radius: 0px;
  background: #ffffff00;
  padding-left: 0px;
  padding-right: 0px;
}
.ant-input:focus {
  border: 0px #555 solid;
  box-shadow: unset;
  border-right-width: 0px !important;
}
.ant-input:hover {
  border: 0px #555 solid;
  box-shadow: unset;
  border-right-width: 0px !important;
}
.color-setting-content {
  font-size: 12px;
  border: 1px solid #c8c8c8;
  background: #ffffff;
  border-radius: 4px;
  width: 120px;
  height: 22px;
  line-height: 40px;
  user-select: none;
  padding-left: 5px;
  margin-left: -5px;
  font-weight: 550;
  color: #000;
  display: inline-flex;
}
.more-colors-img {
  position: relative;
  height: 20px;
  width: 20px;
  left: 226px;
  top: -44px;
  cursor: pointer;
}
.recently-colors-title {
  color: #333;
  font-size: 12px;
  line-height: 30px;
  margin-top: 1px;
}
.standard-colors {
  color: #333;
  margin-left: -5px;
}
</style>